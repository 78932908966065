import {
    AgreementListResponseViewModel,
    AgreementPartyViewModel,
    AgreementViewModel
} from 'core/api/client-portal/autogenerated/data-contracts';
import { FILTER_IN_LIST_PARAMS, TableWidget, TableWidgetProps } from 'lib-ui';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { MouseEventHandler, useMemo } from 'react';
import {
    faArrowDownAZ,
    faCalendarPlus,
    faCircle,
    faCircleExclamation,
    faCircleInfo,
    faClipboardCheck,
    faClipboardUser,
    faCloudArrowDown,
    faCodeBranch,
    faCrown,
    faRetweet,
    faThumbsUp
} from '@fortawesome/pro-solid-svg-icons';
import { faCircleDashed } from '@fortawesome/pro-solid-svg-icons';
import { faClipboardCheck as farClipboardCheck } from '@fortawesome/pro-regular-svg-icons';
import { useModals } from '@ncc-frontend/core';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import { AgreementsTableContext } from './agreements-table-context';
import {
    agreementTestingLevelValueGetter,
    getAgreementTestingLevelOptions
} from './agreement-testing-level-cell-renderer';
import {
    agreementTypeValueGetter,
    getAgreementTypeOptions
} from './agreement-type-cell-renderer';
import AgreementDepositStatusCellRenderer, {
    agreementDepositStatusValueGetter
} from './agreement-deposit-status-cell-renderer';
import AgreementDetailsDateCellRenderer from './agreement-details-date-cell-renderer';
import AgreementLicenseesCellRender, {
    agreementLicenseesOnCellClick,
    agreementLicenseesValueGetter
} from './agreement-licensee-cell-render';
import AgreementLinkCellRenderer from './agreement-link-cell-renderer';
import AgreementStatusCellRenderer, {
    agreementStatusValueGetter
} from './agreement-status-cell-renderer';
import ExportButton from './export/export-button';
import getDateFilter from 'core/ag-grid/get-date-filter';
import timestampFormatter from 'core/formatters/timestamp-formatter';
import useBeautify from 'core/beautifiers/use-beautify';

interface AgreementsTableProps {
    data: AgreementListResponseViewModel | undefined;
    error?: number;
    loading: boolean;
    onReload?: MouseEventHandler<HTMLButtonElement>;
}

function AgreementsTable({
    data,
    error,
    loading,
    onReload
}: AgreementsTableProps) {
    const { i18n, t } = useTranslation();
    const { push } = useModals();
    const goTo = useNavigate();
    const { beautifyDate } = useBeautify();

    const id = 'agreements-table';

    const defaultColDef = useMemo<TableWidgetProps<false>['defaultColDef']>(
        () => ({
            onCellClicked: ({ context: { goTo }, data }) => {
                if (data.agreementId && data.agreementNumber)
                    goTo(
                        `/agreements/${data.agreementId}/${data.agreementNumber}`
                    );
            }
        }),
        []
    );

    const columnDefs = useMemo<TableWidgetProps<false>['columnDefs']>(
        () => [
            {
                cellRenderer: AgreementLinkCellRenderer,
                field: 'agreementNumber',
                headerName: t('agreements.header.agreement')
            },
            {
                cellRenderer: AgreementDepositStatusCellRenderer,
                colId: 'deposit',
                headerName: t('agreements.header.deposit'),
                valueGetter: agreementDepositStatusValueGetter
            },
            {
                field: 'agreementType',
                filter: true,
                filterParams: FILTER_IN_LIST_PARAMS,
                headerName: t('agreements.header.type'),
                valueGetter: agreementTypeValueGetter
            },
            {
                cellRenderer: AgreementStatusCellRenderer,
                field: 'agreementStatus',
                filter: true,
                filterParams: FILTER_IN_LIST_PARAMS,
                headerName: t('agreements.header.status'),
                valueGetter: agreementStatusValueGetter
            },
            {
                ...getDateFilter('lastDepositDate', i18n.language),
                cellRenderer: AgreementDetailsDateCellRenderer,
                colId: 'lastDepositDate',
                headerName: t('agreements.header.last-deposit')
            },
            {
                cellRenderer: (data: { value: string }) => {
                    return (
                        <div
                            className="max-w-[300px] truncate"
                            title={data.value}
                        >
                            <p>{data.value}</p>
                        </div>
                    );
                },
                field: 'softwarePackageName',
                headerName: t('agreements.header.software')
            },
            {
                field: 'renewalDate',
                headerName: t('agreements.header.renewal'),
                valueGetter: ({ data }) =>
                    beautifyDate(
                        timestampFormatter(data.renewalDate, i18n.language, {
                            dateOnly: true
                        })
                    )
            },
            {
                field: 'lastDepositVersion',
                headerName: t('agreements.header.version-deposited')
            },
            {
                field: 'lastDepositLevelOfVerification',
                filter: true,
                filterParams: FILTER_IN_LIST_PARAMS,
                headerName: t('agreements.header.deposit-testing'),
                valueGetter: agreementTestingLevelValueGetter
            },
            {
                cellRenderer: AgreementLicenseesCellRender,
                field: 'licensees',
                filterParams: FILTER_IN_LIST_PARAMS,
                headerName: t('agreements.header.licensees'),
                onCellClicked: agreementLicenseesOnCellClick,
                valueGetter: agreementLicenseesValueGetter
            },
            {
                field: 'softwareOwner',
                headerName: t('agreements.header.software-owner')
            },
            {
                ...getDateFilter('lastVerificationExerciseDate', i18n.language),
                colId: 'lastVerificationExerciseDate',
                headerName: t('agreements.header.last-verification')
            },
            {
                cellRenderer: () => null,
                field: 'registeredNames',
                filter: 'agTextColumnFilter',
                headerComponent: () => null,
                headerName: 'Licensees',
                hide: true,
                lockVisible: true,
                suppressColumnsToolPanel: true,
                suppressMenu: true,
                // eslint-disable-next-line @typescript-eslint/no-explicit-any
                valueGetter: (params: any) => {
                    return (
                        params.data.licensees
                            ?.map(
                                (l: AgreementPartyViewModel) => l.registeredName
                            )
                            .join(', ') || ''
                    );
                }
            }
        ],
        [beautifyDate, i18n.language, t]
    );

    const selectableFields = useMemo<
        TableWidgetProps<false>['selectableFields']
    >(
        () => [
            {
                fieldId: 'agreementNumber',
                icon: <FontAwesomeIcon icon={faCalendarPlus} />,
                label: t('agreements.header.agreement')
            },
            {
                fieldId: 'deposit',
                icon: <FontAwesomeIcon icon={faArrowDownAZ} />,
                label: t('agreements.header.deposit')
            },
            {
                fieldId: 'agreementType',
                icon: <FontAwesomeIcon icon={faCircle} />,
                label: t('agreements.header.type')
            },
            {
                fieldId: 'agreementStatus',
                icon: <FontAwesomeIcon icon={faCircleExclamation} />,
                label: t('agreements.header.status')
            },
            {
                fieldId: 'lastDepositDate',
                icon: <FontAwesomeIcon icon={faCloudArrowDown} />,
                label: t('agreements.header.last-deposit')
            },
            {
                fieldId: 'softwarePackageName',
                icon: <FontAwesomeIcon icon={faCircleInfo} />,
                label: t('agreements.header.software')
            },
            {
                fieldId: 'liveDate',
                icon: <FontAwesomeIcon icon={faRetweet} />,
                label: t('agreements.header.renewal')
            },
            {
                fieldId: 'lastDepositVersion',
                icon: <FontAwesomeIcon icon={faCodeBranch} />,
                label: t('agreements.header.version-deposited')
            },
            {
                fieldId: 'lastDepositLevelOfVerification',
                icon: <FontAwesomeIcon icon={faClipboardCheck} />,
                label: t('agreements.header.deposit-testing')
            },
            {
                fieldId: 'licensees',
                icon: <FontAwesomeIcon icon={faClipboardUser} />,
                label: t('agreements.header.licensees')
            },
            {
                fieldId: 'softwareOwner',
                icon: <FontAwesomeIcon icon={faCrown} />,
                label: t('agreements.header.software-owner')
            },
            {
                fieldId: 'lastVerificationExerciseDate',
                icon: <FontAwesomeIcon icon={faThumbsUp} />,
                label: t('agreements.header.last-verification')
            }
        ],
        [t]
    );

    const filterableFields = useMemo<
        TableWidgetProps<false>['filterableFields']
    >(
        () => [
            {
                colId: 'agreementType',
                filterType: 'text',
                icon: <FontAwesomeIcon icon={faCircleDashed} />,
                mode: 'inList',
                name: t('agreements.header.type'),
                options: getAgreementTypeOptions({ goTo, push, t }),
                searchable: true,
                title: t('agreements.filter.type')
            },
            {
                colId: 'lastDepositLevelOfVerification',
                filterType: 'text',
                icon: <FontAwesomeIcon icon={farClipboardCheck} />,
                mode: 'inList',
                name: t('agreements.header.deposit-testing'),
                options: getAgreementTestingLevelOptions({ goTo, push, t }),
                searchable: true,
                title: t('agreements.filter.testing-level')
            },
            {
                colId: 'lastVerificationExerciseDate',
                dateConstrain: 'past',
                filterType: 'date',
                icon: <FontAwesomeIcon icon={farClipboardCheck} />,
                mode: 'inRange',
                name: t('agreements.header.last-verification'),
                options: [],
                title: t('agreements.filter.last-verification')
            }
        ],
        [goTo, push, t]
    );

    const tableContext = useMemo<AgreementsTableContext>(
        () => ({
            goTo,
            push,
            t
        }),
        [goTo, push, t]
    );

    const noRowsOverlayComponentParams = useMemo<
        TableWidgetProps<false>['noRowsOverlayComponentParams']
    >(() => ({ message: t('agreements.no-data') }), [t]);

    return (
        <TableWidget<AgreementViewModel, false>
            id={id}
            context={tableContext}
            title={t('agreements.title')}
            defaultColDef={defaultColDef}
            columnDefs={columnDefs}
            selectableFields={selectableFields}
            filterableFields={filterableFields}
            rowData={data?.agreements}
            loading={loading}
            post={<ExportButton />}
            noRowsOverlayComponentParams={noRowsOverlayComponentParams}
            error={error}
            onReload={onReload}
        />
    );
}

export default AgreementsTable;
export type { AgreementsTableProps };
