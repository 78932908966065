import { CommonProps } from '@ncc-frontend/core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { PropsWithChildren, ReactElement } from 'react';
import { faSpinner } from '@fortawesome/pro-regular-svg-icons';

import cn from 'core/utils/cn';

interface ModalBodyProps extends CommonProps {
    loading?: boolean;
}

function ModalBody({
    children,
    className,
    loading = false,
    ...restProps
}: PropsWithChildren<ModalBodyProps>): ReactElement | null {
    const cssClasses = cn(
        'overflow-auto mt-escode-l px-[0.20rem] relative',
        loading && 'overflow-hidden',
        className
    );

    return (
        <div {...restProps} className={cssClasses} data-testid="modal-body">
            {loading ? (
                <div className=" h-full w-full pointer-events-none">
                    <div className="absolute top-[calc(50%-3rem)] left-[calc(50%-3rem)]">
                        <FontAwesomeIcon
                            icon={faSpinner}
                            className="animate-spin h-24"
                        />
                    </div>
                    <div className={cn(loading && 'opacity-50')}>
                        {children}
                    </div>
                </div>
            ) : (
                children
            )}
        </div>
    );
}

export default ModalBody;
export type { ModalBodyProps };
