/* eslint-disable @typescript-eslint/no-explicit-any */
import { AxiosResponse } from 'axios';
import { Controller, useFieldArray, useForm } from 'react-hook-form';
import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useModals } from '@ncc-frontend/core';
import { useTranslation } from 'react-i18next';
import { useVirtualizer } from '@tanstack/react-virtual';

import { AddBranchSelectionModalProps } from './types';
import { Button, Input, Modal, Popover } from 'lib-ui';
import { connectionErrorCode } from './error-formatter';
import AddSourceControlConnectionModal from 'ui/source-control-connections/add-source-control-connection-modal/add-source-control-connection-modal';
import Checkbox from './checkbox';
import SearchBar from 'lib-ui/search-bar/search-bar';
import cn from 'core/utils/cn';
import splitIntoGroups from './chunk-creator';
import toastFunctions from 'lib-ui/toast/Toast';
import useVerifyRepositoryConfiguration from 'core/api/client-portal/hooks/source-control-deposits/use-verify-repository-configuration';

type RepositoryChunk = {
    branchName: string | null;
    id: number;
    isSelected: boolean;
    label: string;
    providerId: number;
    selectBranch: boolean;
}[][];

type ConnectionError = {
    error: number;
    label: string;
};

interface ApiResponse {
    isValid: boolean;
    items: { id: number; status: number }[];
    validationErrors: unknown;
}

export default function AddBranchSelectionModal({
    currentConnections,
    methods,
    repositories
}: AddBranchSelectionModalProps) {
    const { t } = useTranslation();
    const { pop, push } = useModals();
    const [searchQuery, setSearchQuery] = useState('');
    const [repoErrors, setRepoErrors] = useState<string[]>([]);
    const [errorsMsgs, setErrorMsgs] = useState<ConnectionError[]>([]);
    const parentRef = useRef<HTMLDivElement>(null);
    const [chunks, setChunks] = useState<RepositoryChunk>([]);
    const [currentChunkIndex, setCurrentChunkIndex] = useState(0);
    const [isVerifying, setIsVerifying] = useState(false);

    const handleAddNewConnection = useCallback(() => {
        push(AddSourceControlConnectionModal, {});
    }, [push]);

    const { control, handleSubmit, setValue, watch } = useForm<{
        repositories: Array<{
            branchName: string | null;
            id: number;
            isSelected: boolean;
            label: string;
            providerId: number;
            selectBranch: boolean;
        }>;
    }>({
        defaultValues: {
            repositories: repositories
                .filter(
                    (connection) =>
                        !currentConnections.some(
                            (existingConnection) =>
                                existingConnection.providerId ===
                                connection.value
                        )
                )
                .map((option) => ({
                    branchName: null,
                    id: Number(option.value),
                    isSelected: false,
                    label: option.label!.toString(),
                    providerId: Number(option.value),
                    selectBranch: false
                }))
        }
    });

    const { fields } = useFieldArray({
        control,
        name: 'repositories'
    });

    function troubledRepositories(errors: any) {
        return errors.items
            .filter((error: any) => error.status !== 0)
            .map((error: any) => `${error.id}`);
    }

    function matchErrorToConnections(errors: any) {
        return errors.items.map((error: any) => ({
            error: error.status,
            label: repositories
                .find((option) => {
                    return option.value === Number.parseInt(error.id);
                })
                ?.label?.toString()
                .split('-')[0]
        }));
    }

    const { isLoading, mutate: verify } = useVerifyRepositoryConfiguration({
        onError(error) {
            const {
                response: { data }
            } = error;
            const reposWithIssues = troubledRepositories(data);
            const matchedConnections = matchErrorToConnections(data);

            setRepoErrors(reposWithIssues);
            setErrorMsgs(matchedConnections);
            toastFunctions.error(
                'Error with submission, please check below',
                'duration-500'
            );

            setIsVerifying(false);
        },
        onSuccess(response: AxiosResponse<ApiResponse>) {
            const nextChunkIndex = currentChunkIndex + 1;
            if (nextChunkIndex < chunks.length) {
                setCurrentChunkIndex(nextChunkIndex);
            } else {
                const newConnections = [
                    ...currentConnections,
                    ...watch('repositories').filter((repo) => repo.isSelected)
                ];
                methods.setValue('connections', newConnections);
                setIsVerifying(false);
                pop();
                toastFunctions.success(
                    t('toast.control-connection-success'),
                    'duration-500'
                );
                setRepoErrors([]);
            }
        }
    });

    const filteredConnections = useMemo(() => {
        if (!searchQuery) {
            return fields;
        }
        return fields.filter((repo) => {
            const label = repositories.find(
                (option) => option.label === repo.label
            )?.label;

            return label
                ?.toString()
                .toLowerCase()
                .includes(searchQuery.toLowerCase());
        });
    }, [fields, repositories, searchQuery]);

    const rowVirtualizer = useVirtualizer({
        count: filteredConnections.length,
        estimateSize: () => 80,
        getScrollElement: () => parentRef.current,
        overscan: 25
    });

    const verifyChunk = (index: number) => {
        if (index < chunks.length) {
            verify({
                data: chunks[index]
            });
        }
    };

    useEffect(() => {
        if (isVerifying && currentChunkIndex < chunks.length) {
            // Recursively verify next chunk
            verifyChunk(currentChunkIndex);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [currentChunkIndex, isVerifying]);

    const onSubmit = handleSubmit(async (data) => {
        const selectedRepositories = data.repositories.filter(
            (repo) => repo.isSelected
        );
        methods.setValue('connectionsAdded', selectedRepositories);

        if (selectedRepositories.length > 20) {
            const newChunks = splitIntoGroups(selectedRepositories);

            setChunks(newChunks);
            setCurrentChunkIndex(0);
            setIsVerifying(true);
        } else {
            setIsVerifying(true);
            verify({
                data: selectedRepositories
            });
        }
    });

    return (
        <Modal size="l" className="w-[740px]">
            <Modal.Header>
                <div className="flex w-full items-center justify-between">
                    {t('add-source-control-connection.title')}
                </div>
            </Modal.Header>
            <div className="h-10 mt-3">
                <SearchBar
                    disabled={isLoading}
                    placeholder="Search connections..."
                    width="w-full"
                    inputStyles="rounded-lg bg-general-grey-grey-10 absolute top-0 left-0"
                    value={searchQuery}
                    onChange={(e) => setSearchQuery(e.target.value)}
                />
            </div>
            <div className="flex justify-between mt-5">
                <div className="flex items-center px-1">
                    <input
                        type="checkbox"
                        disabled={searchQuery !== '' || isLoading}
                        onChange={(e) => {
                            const isChecked = e.target.checked;
                            fields.forEach((_, index) => {
                                setValue(
                                    `repositories.${index}.isSelected`,
                                    isChecked
                                );
                            });
                        }}
                    />
                    <p className="ml-2 text-sm text-general-grey-grey-100">
                        {t('source-control.select-all')}
                    </p>
                </div>
                {!searchQuery ? (
                    <div className="flex gap-2">
                        <p className="text-xs text-general-grey-grey-100 font-normal">
                            {
                                watch('repositories').filter(
                                    (repo) => repo.isSelected
                                ).length
                            }{' '}
                            <span className="text-general-grey-grey-80">
                                selected
                            </span>
                        </p>
                        <p className="text-xs text-general-grey-grey-100 font-normal">
                            {filteredConnections.length}{' '}
                            <span className="text-general-grey-grey-80">
                                connections
                            </span>
                        </p>
                    </div>
                ) : (
                    <p className="text-xs text-general-grey-grey-100 font-normal">
                        {filteredConnections.length}{' '}
                        <span className="text-general-grey-grey-80">
                            of{' '}
                            <span className="text-general-grey-grey-100">
                                {repositories.length}
                            </span>{' '}
                            connections
                        </span>
                    </p>
                )}
            </div>
            <Modal.Body className="relative mt-0" loading={isLoading}>
                {filteredConnections.length === 0 ? (
                    <div className="flex items-center justify-center h-40">
                        <p className="text-general-grey-grey-60">
                            {searchQuery
                                ? t('No matching connections found')
                                : t('No connections available')}
                        </p>
                    </div>
                ) : (
                    <div
                        ref={parentRef}
                        className="h-[70rem] overflow-auto mt-0.5 pl-[2px]"
                    >
                        <div
                            className="relative w-full"
                            style={{
                                height: `${rowVirtualizer.getTotalSize()}px`
                            }}
                        >
                            {rowVirtualizer
                                .getVirtualItems()
                                .map((virtualItem) => {
                                    const item =
                                        filteredConnections[virtualItem.index];
                                    const fieldIndex = fields.findIndex(
                                        (f) => f.id === item.id
                                    );

                                    return (
                                        <div
                                            key={fieldIndex}
                                            className="absolute top-0 left-0 w-full"
                                            style={{
                                                height: `${virtualItem.size}px`,
                                                transform: `translateY(${virtualItem.start}px)`
                                            }}
                                        >
                                            <div className="flex w-full gap-2 items-center justify-between border-b border-gray-500 py-5">
                                                <div className="flex w-[46%] min-w-48 gap-3 text-nowrap items-center">
                                                    <Controller
                                                        name={`repositories.${fieldIndex}.isSelected`}
                                                        control={control}
                                                        render={({ field }) => {
                                                            return (
                                                                <Checkbox
                                                                    id={`wholeRepository-${fieldIndex}`}
                                                                    value="wholeRepository"
                                                                    name={String(
                                                                        fieldIndex
                                                                    )}
                                                                    error={repoErrors.includes(
                                                                        item.providerId.toString()
                                                                    )}
                                                                    errorTextStyles
                                                                    checked={
                                                                        field.value
                                                                    }
                                                                    onChange={(
                                                                        e
                                                                    ) => {
                                                                        field.onChange(
                                                                            e
                                                                                .target
                                                                                .checked
                                                                        );
                                                                        if (
                                                                            !e
                                                                                .target
                                                                                .checked
                                                                        ) {
                                                                            setValue(
                                                                                `repositories.${item.providerId}.selectBranch`,
                                                                                false
                                                                            );
                                                                            setValue(
                                                                                `repositories.${item.providerId}.branchName`,
                                                                                null
                                                                            );
                                                                        }
                                                                    }}
                                                                    label={
                                                                        <Popover
                                                                            text={connectionErrorCode(
                                                                                errorsMsgs
                                                                                    .find(
                                                                                        (
                                                                                            error
                                                                                        ) =>
                                                                                            error.label ===
                                                                                            item.label
                                                                                                .toString()
                                                                                                .split(
                                                                                                    '-'
                                                                                                )[0]
                                                                                    )
                                                                                    ?.error.toString() ||
                                                                                    item.label
                                                                                        .toString()
                                                                                        .split(
                                                                                            '-'
                                                                                        )[0]
                                                                            )}
                                                                            origin="origin-right"
                                                                            tooltipStyle="left-60 -top-2"
                                                                        >
                                                                            <p className="space-x-3 text-sm w-[270px] truncate cursor-pointer">
                                                                                <span className="text-xs text-general-grey-grey-100 font-semibold">
                                                                                    #
                                                                                    {virtualItem.index +
                                                                                        1}
                                                                                </span>
                                                                                <span
                                                                                    className={cn(
                                                                                        'font-normal',
                                                                                        field.value &&
                                                                                            'font-semibold'
                                                                                    )}
                                                                                >
                                                                                    {
                                                                                        item
                                                                                            .label!.toString()
                                                                                            .split(
                                                                                                '-'
                                                                                            )[0]
                                                                                    }
                                                                                </span>
                                                                            </p>
                                                                        </Popover>
                                                                    }
                                                                />
                                                            );
                                                        }}
                                                    />
                                                </div>
                                                <div className="flex flex-1 items-center justify-between gap-2 text-sm">
                                                    <div className="flex flex-1 items-center justify-end gap-4 text-sm w-[60%]">
                                                        <div className="flex items-center text-wrap">
                                                            <Controller
                                                                name={`repositories.${fieldIndex}.selectBranch`}
                                                                control={
                                                                    control
                                                                }
                                                                render={({
                                                                    field
                                                                }) => (
                                                                    <Checkbox
                                                                        id={`selectBranch-${fieldIndex}`}
                                                                        name={String(
                                                                            fieldIndex
                                                                        )}
                                                                        value="selectBranch"
                                                                        checked={
                                                                            field.value
                                                                        }
                                                                        disabled={
                                                                            !watch(
                                                                                `repositories.${fieldIndex}.isSelected`
                                                                            )
                                                                        }
                                                                        onChange={(
                                                                            e
                                                                        ) => {
                                                                            field.onChange(
                                                                                e
                                                                                    .target
                                                                                    .checked
                                                                            );
                                                                            if (
                                                                                !e
                                                                                    .target
                                                                                    .checked
                                                                            ) {
                                                                                setValue(
                                                                                    `repositories.${fieldIndex}.branchName`,
                                                                                    null
                                                                                );
                                                                            }
                                                                        }}
                                                                        label={
                                                                            <p
                                                                                className={cn(
                                                                                    'font-normal text-[13px]',
                                                                                    watch(
                                                                                        `repositories.${fieldIndex}.isSelected`
                                                                                    ) &&
                                                                                        'font-semibold'
                                                                                )}
                                                                            >
                                                                                {t(
                                                                                    'add-source-control-connection.select-branch'
                                                                                )}
                                                                            </p>
                                                                        }
                                                                    />
                                                                )}
                                                            />
                                                        </div>
                                                        <Controller
                                                            name={`repositories.${fieldIndex}.branchName`}
                                                            control={control}
                                                            render={({
                                                                field
                                                            }) => {
                                                                return (
                                                                    <Input
                                                                        {...field}
                                                                        id={String(
                                                                            fieldIndex
                                                                        )}
                                                                        className={cn(
                                                                            'w-52',
                                                                            repoErrors.includes(
                                                                                item.providerId.toString()
                                                                            ) &&
                                                                                field.value !==
                                                                                    null &&
                                                                                'border-general-red-red-100'
                                                                        )}
                                                                        placeholder="Branch name"
                                                                        disabled={
                                                                            !watch(
                                                                                `repositories.${fieldIndex}.selectBranch`
                                                                            )
                                                                        }
                                                                        required={watch(
                                                                            `repositories.${fieldIndex}.selectBranch`
                                                                        )}
                                                                        value={
                                                                            field.value ??
                                                                            ''
                                                                        }
                                                                    />
                                                                );
                                                            }}
                                                        />
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    );
                                })}
                        </div>
                    </div>
                )}
            </Modal.Body>
            <Modal.Footer showCloseButtonAtEnd>
                <div className="flex w-full gap-4 justify-between">
                    <Button
                        onClick={handleAddNewConnection}
                        variant="secondary"
                    >
                        {t('source-control.add-connection')}
                    </Button>
                    <Button
                        variant="primary"
                        onClick={onSubmit}
                        loading={isLoading}
                        disabled={isLoading}
                    >
                        {t('source-control.save')}
                    </Button>
                </div>
            </Modal.Footer>
        </Modal>
    );
}
