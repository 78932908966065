function errorFormatter(errorMessage: string) {
    switch (errorMessage) {
        case 'BadCredentials':
            return `Invalid Credentials`;

        case 'BadBranch':
            return `Invalid Branch`;

        case 'UnknownError':
            return `Unknown Error`;

        case 'BadUrl':
            return `Bad URL`;

        case 'Timeout':
            return `Timeout`;

        default:
            return 'There has been an error, please try again.';
    }
}

function connectionErrorCode(errorCode: string) {
    switch (errorCode) {
        case '0':
            return 'None'; // should never see, as has been filtered out
        case '1':
            return `Bad URL`;
        case '2':
            return `Invalid Credentials`;
        case '3':
            return `Invalid Branch`;
        case '4':
            return `Unknown Error`;
        case '5':
            return `Timeout`;
        default:
            return errorCode; // display connection name instead of error in popover
    }
}

export { connectionErrorCode };
export default errorFormatter;
