/* eslint-disable @typescript-eslint/no-explicit-any */
import { ChangeEvent, useCallback, useEffect, useMemo, useState } from 'react';
import { DepositFileItem } from 'core/api/client-portal/autogenerated/data-contracts';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCloudArrowUp } from '@fortawesome/pro-regular-svg-icons';
import { useAuth } from 'react-oidc-context';
import { useModals } from '@ncc-frontend/core';
import { useTranslation } from 'react-i18next';
import CardProcess from 'lib-ui/card-process/card-process';

import useDeleteFile from 'core/api/spectre-agent/hooks/delete-file/use-delete-file';
import useSpectreControlAuth from 'core/api/spectre-control/hooks/spectre-control-auth/use-spectre-control-auth';
import useStartFileUpload from 'core/api/spectre-agent/hooks/upload-file/use-start-file-upload';
import useUploadsFilesBlocks, {
    UploadsFilesBlocks
} from 'core/api/spectre-agent/hooks/uploads-files-block/use-uploads-files-block';
import useUploadsFilesPrepare, {
    UploadsFilesPrepareData
} from 'core/api/spectre-agent/hooks/uploads-files-prepare/use-uploads-files-prepare';
import useUploadsStart from 'core/api/spectre-agent/hooks/uploads-start/use-uploads-start';

import {
    AuthToken,
    CustomFileParams,
    DepositData,
    MergedFileItem
} from 'ui/deposit-now/upload-step/upload-types';
import { VVUploaderProps, initialDepositData } from './vv-types';
import { isEmpty } from 'lodash';
import { useBoolean } from 'core/utils/useBoolean';
import { useQueryClient } from 'react-query';
import OversizedfilesModal from './oversized-files-modal';
import classNames from 'classnames';
import useAgreementsDepositsFiles from 'core/api/client-portal-v2/hooks/deposits/use-agreements-deposits-files';
import useDepositNowStore from 'core/stores/use-deposit-now-store';
import useRefreshToken from 'core/api/spectre-control/hooks/spectre-control-refresh/use-refresh-token';
import useRequestSpectreToken from 'core/api/client-portal/hooks/request-token/use-request-spectre-token';

type CustomEvent =
    | ChangeEvent<HTMLInputElement>
    | React.DragEvent<HTMLLabelElement>;

const VVUploader = ({ currentDataCentre, depositId }: VVUploaderProps) => {
    const auth = useAuth();
    const queryClient = useQueryClient();
    const { t } = useTranslation();
    const { push } = useModals();

    const {
        setDepositUniqueReference,
        setIsUploadingFile,
        setVVToken,
        shouldClaimToken
    } = useDepositNowStore();

    const {
        data: depositFilesFetched,
        isLoading: isDepositsLoading,
        refetch: refetchDeposits
    } = useAgreementsDepositsFiles(depositId);

    const {
        setFalse: setDeletingFalse,
        setTrue: setDeletingTrue,
        value: isDeleting
    } = useBoolean(false);

    const [depositFiles, setDepositFiles] = useState<MergedFileItem[]>(
        depositFilesFetched?.depositFiles || []
    );
    const [queuedFiles, setQueuedFiles] = useState<MergedFileItem[]>([]);
    const [currentFileProcessing, setCurrentFileProcessing] =
        useState<MergedFileItem | null>();
    const [depositReferences, setDepositReferences] =
        useState<DepositData>(initialDepositData);
    const [filePercentCompleted, setFilePercentCompleted] = useState<number>(0);

    const depositUniqueReference =
        depositFilesFetched?.depositFiles[0]?.depositUniqueReference ??
        depositReferences.depositUniqueReference;

    const isFileBeingVerified = useMemo(
        () =>
            depositFiles?.some(
                (file: MergedFileItem) =>
                    file.verificationState === 0 && file.completed
            ) ?? false,
        [depositFiles]
    );

    const isUploading = queuedFiles.some(
        (el) => el.uploadStatus === 'uploading'
    );

    let uniqueReference = depositReferences.uniqueReference || '';

    const { data: spectreToken, isLoading: isTokenLoading } =
        useRequestSpectreToken(depositId, {
            enabled: !isDepositsLoading && currentDataCentre !== ''
        });

    const spectre_token: AuthToken | any = spectreToken;

    const { data: spectreAuthToken, isLoading: isSpectreAuthLoading } =
        useSpectreControlAuth(spectre_token, {
            enabled: shouldClaimToken
        });

    const { data: refreshedToken, refetch: refetchRefreshToken } =
        useRefreshToken();

    // const spectre_authToken = refreshedToken ?? spectreAuthToken;

    useEffect(() => {
        // get new spectre Cookie before it expires
        const refreshCookieTimeout = 40 * 60 * 1000; // 40 minutes
        // const refreshCookieTimeout = 8 * 1000; // 8 seconds for testing

        const intervalId = setInterval(() => {
            queryClient.invalidateQueries('use-request-token');
        }, refreshCookieTimeout);

        return () => clearInterval(intervalId);
    }, [queryClient]);

    useEffect(() => {
        // set the confirmVVToken to the spectre_authToken and depositUniqueReference
        setVVToken(refreshedToken ?? spectreAuthToken);
    }, [setVVToken, depositUniqueReference, refreshedToken, spectreAuthToken]);

    useEffect(() => {
        // refresh token data each 10 minutes
        if (!spectreAuthToken || depositUniqueReference === '') return;

        const refreshTimeout = 8 * 60 * 1000; // 8 minutes
        // const refreshTimeout = 15 * 1000; // 15 seconds for testing

        const intervalId = setInterval(() => {
            refetchRefreshToken();
        }, refreshTimeout);

        return () => clearInterval(intervalId);
    }, [depositUniqueReference, refetchRefreshToken, spectreAuthToken]);

    const isDataCentreReady = !isEmpty(currentDataCentre);

    const isAnyFileBeingDeleted = queuedFiles.some(
        (file) => file.uploadStatus === 'deleting'
    );

    const handleDragOver = useCallback(
        (event: React.DragEvent<HTMLLabelElement>) => {
            event.preventDefault();
        },
        []
    );

    const errorQueueFile = () => {
        if (isDeleting) return;

        setFilePercentCompleted(0);
        setCurrentFileProcessing(null);

        const firstFileInQueue = queuedFiles[0];
        const updatedQueuedFiles = queuedFiles.filter(
            (file) => file.fileName !== firstFileInQueue.fileName
        );
        setQueuedFiles(updatedQueuedFiles);
        refetchDeposits();
    };

    const { cancelUpload, uploadFilesBlocksFn } = useUploadsFilesBlocks({
        onError(error, variables, context) {
            error && errorQueueFile();
        },
        onSuccess() {
            // refetch data
            // TODO: change it when the service is quicker.

            if (currentFileProcessing) {
                const currentFileIndex = queuedFiles.findIndex((file) => {
                    return (
                        file.file?.name === currentFileProcessing.fileName &&
                        file.file?.size === currentFileProcessing.fileSize
                    );
                });

                if (
                    currentFileIndex !== -1 &&
                    queuedFiles[currentFileIndex].uploadStatus !== 'deleting'
                ) {
                    queuedFiles[currentFileIndex].uploadStatus = 'verifying';
                }
            }

            setTimeout(() => {
                setCurrentFileProcessing(null);
                // setIsUploadingFile(false);
                setFilePercentCompleted(0);
                refetchDeposits();
            }, 2500);
        },
        onUploadProgress(percentCompleted) {
            setFilePercentCompleted(percentCompleted);
        }
    });

    const { mutate: startUploadMutation } = useStartFileUpload({
        onError() {
            errorQueueFile();
        },
        onSuccess(data) {
            // add depositUniqueReference and uniqueReference to the current file
            if (currentFileProcessing) {
                setCurrentFileProcessing((prev) => {
                    return {
                        ...prev!,
                        depositUniqueReference:
                            data.data.depositUniqueReference,
                        uniqueReference: data.data.uniqueReference
                    };
                });
                uniqueReference = data.data.uniqueReference;
            }

            setDepositReferences(data.data);
            const depositUniqueReferenceData = data.data.depositUniqueReference;
            const variables = {
                depositId: depositUniqueReferenceData,
                uniqueReference: data.data.uniqueReference
            };

            uploadsStartMutation(variables);
        }
    });

    const { mutate: uploadsStartMutation } = useUploadsStart({
        onError() {
            errorQueueFile();
        },
        onSuccess(data, variables, context) {
            if (data && currentFileProcessing) {
                const currentData: UploadsFilesPrepareData = {
                    depositUniqueReference: variables.depositId.toString(),
                    uniqueReference:
                        variables.uniqueReference!.toString() ?? '',
                    variables: {
                        fileName: currentFileProcessing?.fileName,
                        size: currentFileProcessing?.fileSize
                    }
                };

                const currentFileIndex = queuedFiles.findIndex((file) => {
                    return (
                        file.file?.name === currentData.variables.fileName &&
                        file.file?.size === currentData.variables.size
                    );
                });
                if (currentFileIndex !== -1) {
                    queuedFiles[currentFileIndex].uploadStatus = 'uploading';
                }

                uploadsFilesPrepareMutation(currentData);
            }
        }
    });

    const { mutate: uploadsFilesPrepareMutation } = useUploadsFilesPrepare({
        onError() {
            errorQueueFile();
        },
        onSuccess(data) {
            const uploadsFilesPrepareData = data.data;

            if (currentFileProcessing?.file) {
                const file = currentFileProcessing.file;

                const config: UploadsFilesBlocks = {
                    blocks: uploadsFilesPrepareData,
                    file,
                    uniqueReference
                };
                uploadFilesBlocksFn(config);
            }
        }
    });

    const { isLoading: isDeleteLoading, mutate: deleteFile } = useDeleteFile({
        onError() {
            errorQueueFile();
        },
        onSettled(data, error, variables) {
            setFilePercentCompleted(0);

            if (queuedFiles.some((file) => file.uploadStatus === 'deleting')) {
                const fileToDeleteIndex = queuedFiles.findIndex(
                    (file) => file.uploadStatus === 'deleting'
                );

                const updatedQueuedFiles = [...queuedFiles];
                updatedQueuedFiles.splice(fileToDeleteIndex, 1);
                setDeletingFalse();

                setTimeout(() => {
                    setQueuedFiles(updatedQueuedFiles);
                    setCurrentFileProcessing(null);

                    // refetchDeposits();
                }, 6000);
                return;
            }

            if (queuedFiles.some((file) => file.uploadStatus === 'uploading')) {
                setDepositFiles((prev) => {
                    const updatedDepositFiles = [...prev];
                    const fileToDeleteIndex = updatedDepositFiles.findIndex(
                        (file) => file.uniqueReference === uniqueReference
                    );

                    updatedDepositFiles.splice(fileToDeleteIndex, 1);
                    return updatedDepositFiles;
                });
                return;
            }
            // setTimeout(() => {
            //     refetchDeposits();
            // }, 2000);
        }
    });

    const handleProceedQueue = useCallback(() => {
        // if (isAnyFileBeingDeleted || isDeleting) return; // if there is a file being deleted, do not start the upload process
        if (isAnyFileBeingDeleted) return; // if there is a file being deleted, do not start the upload process
        setIsUploadingFile(true);

        if (queuedFiles.length > 0) {
            setIsUploadingFile(true);
            isDeleting && setDeletingFalse();
            // Check if the file is already in the depositFiles
            const isQueuedFilesInDepositFiles = queuedFiles.some((queuedFile) =>
                depositFiles.some(
                    (depositFile) =>
                        depositFile.fileName === queuedFile.fileName &&
                        depositFile.fileSize === queuedFile.fileSize
                )
            );

            // If there are repeated files in depositFiles, show FilesRepeated modal
            if (isQueuedFilesInDepositFiles) {
                // Filter queuedFiles to get files without matches in depositFiles
                const queueFilesWithoutDepositFiles = queuedFiles.filter(
                    (queuedFile) =>
                        !depositFiles.some(
                            (depositFile) =>
                                depositFile.fileName === queuedFile.fileName &&
                                depositFile.fileSize === queuedFile.fileSize
                        )
                );

                // Update queuedFiles with files not found in depositFiles
                setQueuedFiles(queueFilesWithoutDepositFiles);
                return;
            } else {
                const firstQueueStatusFile = queuedFiles.find(
                    (file) => file.uploadStatus === 'queued'
                );

                if (firstQueueStatusFile && !isDeleting) {
                    const queueFileUploading = queuedFiles.some(
                        (file) => file.uploadStatus === 'uploading'
                    );
                    if (queueFileUploading) return;

                    queuedFiles[0].uploadStatus = 'uploading';
                    setCurrentFileProcessing(queuedFiles[0]);
                    setQueuedFiles(queuedFiles);
                    const fileToProcess: MergedFileItem = firstQueueStatusFile;

                    if (fileToProcess.file) {
                        setFilePercentCompleted(0);
                        // startFileUpload(fileToProcess.file);
                        const fileToUpload = fileToProcess.file;

                        const mutatedObject: CustomFileParams = {
                            depositId: depositId,
                            fileHash: 'hash',
                            fileId: fileToUpload.name,
                            fileName: fileToUpload.name,
                            fileSize: fileToUpload.size,
                            lastModified: fileToUpload.lastModified,
                            uploadedById: auth.user!.id_token!.toString()
                        };

                        const currentFileIndex = queuedFiles.findIndex(
                            (file) => {
                                return (
                                    file.file?.name === fileToUpload.name &&
                                    file.file.size === fileToUpload.size
                                );
                            }
                        );

                        if (currentFileIndex !== -1) {
                            queuedFiles[currentFileIndex].uploadStatus =
                                'uploading';
                            setQueuedFiles(queuedFiles);
                        }
                        startUploadMutation(mutatedObject);
                    }
                }
                return;
            }
        }
        return;
    }, [
        isAnyFileBeingDeleted,
        queuedFiles,
        setIsUploadingFile,
        isDeleting,
        setDeletingFalse,
        depositFiles,
        depositId,
        auth.user,
        startUploadMutation
    ]);

    const handleSelectedFiles = (event: CustomEvent) => {
        event.preventDefault();
        localStorage.setItem('isAnyFileBeingUploaded', 'true');

        const isChangeEvent =
            'target' in event && event.target instanceof HTMLInputElement;

        const filesSelected = isChangeEvent
            ? Array.from((event.target as HTMLInputElement).files || [])
            : Array.from(
                  (event as React.DragEvent<HTMLLabelElement>).dataTransfer
                      .files || []
              );

        const maxFileSizeInBytes = 100 * 1024 * 1024 * 1024; // 100 GB

        const filteredFiles = filesSelected.filter((file) => {
            if (file.size > maxFileSizeInBytes) {
                push(OversizedfilesModal, { isOnPrem: false });
                return false; // Exclude the file from the filtered array
            }
            return true; // Include the file in the filtered array
        });

        const mutatedObjectArray: MergedFileItem[] = filteredFiles.map(
            (file: File) => ({
                completed: false,
                file: file,
                fileName: file.name,
                fileSize: file.size,
                progress: 0,
                uploadStatus: 'queued',
                verificationState: 0
            })
        );

        setQueuedFiles((prev) => [...prev, ...mutatedObjectArray]);
    };

    const handleDeleteFile = async (
        fileItem: DepositFileItem,
        queue?: boolean
    ) => {
        if (!fileItem || !spectreAuthToken) return;

        if (!queue) {
            // Deposit File Data
            const fileItemIndex = depositFiles.findIndex((file) => {
                return (
                    file.fileName === fileItem.fileName &&
                    file.fileSize === fileItem.fileSize
                );
            });

            if (fileItemIndex !== -1) {
                const updatedDepositFiles = [...depositFiles];
                updatedDepositFiles[fileItemIndex].uploadStatus = 'deleting';
                setDepositFiles(updatedDepositFiles);
            }

            deleteFile({
                depositUniqueReference: fileItem.depositUniqueReference,
                uniqueReference: fileItem.uniqueReference
            });

            setTimeout(() => {
                refetchDeposits();
            }, 1500);
        } else {
            const fileToDelete = queuedFiles.find((file) => {
                return (
                    file.fileName === fileItem.fileName &&
                    file.fileSize === fileItem.fileSize
                );
            });
            // are we deleting a file that is currently uploading?
            if (
                fileToDelete?.uploadStatus === 'uploading' ||
                fileToDelete?.uploadStatus === 'verifying'
            ) {
                cancelUpload();
                setDeletingTrue();

                fileToDelete.uploadStatus = 'deleting';

                deleteFile({
                    depositUniqueReference,
                    uniqueReference: uniqueReference
                });
                return;
            }

            setQueuedFiles((prev) => {
                const updatedQueuedFiles = prev.filter(
                    (qFile) =>
                        qFile.fileName !== fileItem.fileName ||
                        qFile.fileSize !== fileItem.fileSize
                );
                return updatedQueuedFiles;
            });
        }
    };

    // Start the upload process
    useEffect(() => {
        queuedFiles.length > 0 && !isDeleting && handleProceedQueue();
    }, [handleProceedQueue, isDeleting, queuedFiles]);

    // Update the depositFiles when the depositFilesFetched is updated
    useEffect(() => {
        if (depositFilesFetched && !isDepositsLoading) {
            const depositFilesNotInQueue =
                // eslint-disable-next-line @typescript-eslint/no-explicit-any
                depositFilesFetched.depositFiles.filter((depositFile: any) => {
                    return !queuedFiles.some(
                        (queuedFile) =>
                            queuedFile.fileName === depositFile.fileName &&
                            queuedFile.fileSize === depositFile.fileSize
                    );
                });

            setDepositFiles(depositFilesNotInQueue || []);
        }
    }, [depositFilesFetched, isDepositsLoading, queuedFiles]);

    // Update the depositFiles when the depositFilesFetched is updated
    useEffect(() => {
        if (!isDepositsLoading && !isUploading) {
            setDepositFiles(depositFilesFetched?.depositFiles ?? []);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isDepositsLoading, depositFilesFetched]);

    // Update the depositUniqueReference when the depositUniqueReference is updated
    useEffect(() => {
        setDepositUniqueReference(depositUniqueReference);
    }, [depositUniqueReference, setDepositUniqueReference]);

    // When large files are uploaded, they take a long time to be verified by the server.
    useEffect(() => {
        let intervalId: NodeJS.Timeout | null = null;
        if (!isFileBeingVerified && queuedFiles.length === 0) {
            localStorage.setItem('isAnyFileBeingUploaded', 'false');
            setIsUploadingFile(false);
            // setVerifyingFalse();
        }

        if (isFileBeingVerified) {
            intervalId = setInterval(() => {
                refetchDeposits();
            }, 8000);
        }

        return () => {
            if (intervalId) {
                clearInterval(intervalId);
            }
        };
    }, [
        isFileBeingVerified,
        queuedFiles.length,
        refetchDeposits,
        setIsUploadingFile
    ]);

    return (
        <div className="grid grid-cols-2 gap-14">
            <label
                className={classNames(
                    'flex',
                    'h-40',
                    'min-h-full',
                    'gap-2.5',
                    'rounded-lg',
                    'justify-center',
                    'items-center',
                    'font-semibold',
                    {
                        'bg-brand-escode-neonblue-neonblue-10':
                            isDataCentreReady,
                        'bg-escode-grey-10': isDataCentreReady,
                        'bg-general-grey-grey-10': !isDataCentreReady,
                        'border-2': isDataCentreReady,
                        'border-brand-escode-neonblue-neonblue-80':
                            isDataCentreReady,
                        'border-dashed': 'isDataCentreReady',
                        'border-escode-grey-50': !isDataCentreReady,
                        'cursor-pointer': isDataCentreReady,
                        'text-brand-escode-neonblue-neonblue-100':
                            isDataCentreReady,
                        'text-general-grey-grey-60': !isDataCentreReady,
                        'text-general-grey-grey-90': !isDataCentreReady
                    }
                )}
                onDrop={handleSelectedFiles}
                onDragOver={handleDragOver}
            >
                {isDataCentreReady ? (
                    <>
                        <FontAwesomeIcon icon={faCloudArrowUp} />
                        <span>{t('file-uploader.title')}</span>
                    </>
                ) : (
                    <span className="text-sm font-light">
                        {t('file-uploader.data-centre-disabled')}
                    </span>
                )}
                <input
                    type={'file'}
                    multiple
                    hidden
                    className=""
                    onChange={handleSelectedFiles}
                    data-testid="vv-uploader"
                    disabled={!isDataCentreReady}
                />
            </label>
            {!!isTokenLoading || !!isSpectreAuthLoading ? (
                <div>loading...</div>
            ) : (
                <div className="space-y-2.5">
                    {queuedFiles !== null &&
                        queuedFiles.length > 0 &&
                        queuedFiles.map((fileItem, index) => (
                            <CardProcess
                                key={fileItem.fileName! + index}
                                onDeleteFile={() =>
                                    handleDeleteFile(fileItem, true)
                                }
                                file={fileItem}
                                percentCompleted={filePercentCompleted}
                            />
                        ))}
                    {depositFiles !== null &&
                        depositFiles.map((fileItem, index) => (
                            <CardProcess
                                key={fileItem?.fileId + '' + index}
                                onDeleteFile={() =>
                                    !isDeleteLoading &&
                                    handleDeleteFile(fileItem)
                                }
                                file={fileItem}
                            />
                        ))}
                </div>
            )}
        </div>
    );
};

export default VVUploader;
