/* eslint-disable sort-keys-fix/sort-keys-fix */
import * as yup from 'yup';

const requiredMessage = 'This field is required';
const radioMessage = 'This field is required, please select "Yes" or "No"';
const licenseesMessage = 'Please select licensee(s)';
const maxLengthMessage = 'Must not exceed 500 characters';

const schema = yup.object({
    applicationCategory: yup.string(),
    approximateSizeOfMediaContent: yup
        .number()
        .required(requiredMessage)
        .typeError(requiredMessage),
    containsVirtualMachine: yup
        .mixed<string | boolean>()
        .required(radioMessage)
        .typeError(radioMessage),
    contentsOfThisDeposit: yup.lazy((value) => {
        return yup
            .object()
            .shape({
                sourceCode: yup.boolean(),
                development: yup.boolean(),
                environmentOrBuildTools: yup.boolean(),
                database: yup.boolean(),
                testEnvironment: yup.boolean(),
                otherData: yup.boolean(),
                configurationFiles: yup.boolean()
            })
            .test({
                test: (obj) => {
                    // Check if at least one field is true
                    return Object.values(obj).some((value) => value === true);
                },
                message: 'At least one of these options must be selected'
            });
    }),
    fileTypes: yup.mixed().when('contentsOfThisDeposit.sourceCode', {
        is: true,
        then: yup
            .array()
            .min(1, requiredMessage)
            .of(
                yup.object().shape({
                    label: yup.string().required(),
                    value: yup.number().required()
                })
            )
    }),
    financialServicesCategory: yup.string(),
    hardwareUsedToCreateTheDeposit: yup.string().max(500, maxLengthMessage),
    isForAMediaFileReview: yup
        .string()
        .required(radioMessage)
        .typeError(radioMessage),
    isForAScheduledVerificationExercise: yup
        .string()
        .required(radioMessage)
        .typeError(radioMessage),
    isForASpecificLicensee: yup.string(),
    licenseesThatRequireThisDeposit: yup
        .array()
        .when('isForASpecificLicensee', {
            is: (value: string) => value === '1',
            then: yup
                .array()
                .min(1, licenseesMessage)
                .of(
                    yup.object().shape({
                        id: yup.string().required(),
                        label: yup.string().required(),
                        value: yup.number().required()
                    })
                )
        }),
    operatingSystemUsed: yup.string().max(500, maxLengthMessage),
    otherFileTypes: yup.string().when('fileTypes', {
        is: (fileTypes: { id: string; label: string }[]) =>
            fileTypes.some((fileType) => fileType.label === 'Other'),
        then: yup.string().required('Please specify other file types')
    }),
    softwareOwnerCompanyName: yup.string().required(requiredMessage),
    softwarePackageName: yup
        .string()
        .required(requiredMessage)
        .max(255, 'Must not exceed 255 characters'),
    versionOrReleaseNumber: yup
        .string()
        .required(requiredMessage)
        .max(150, 'Must not exceed 150 characters')
});

export default schema;
