import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { FormProvider, SubmitHandler, useForm } from 'react-hook-form';
import {
    faArrowLeft,
    faArrowRight,
    faSpinner
} from '@fortawesome/pro-regular-svg-icons';
import { useAuth } from 'react-oidc-context';
import { useCallback, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { yupResolver } from '@hookform/resolvers/yup';
import Turnstile, { useTurnstile } from 'react-turnstile';

import { CheckField, InputField, SelectField } from 'lib-ui';
import { FormData, titles } from './constants';
import Button from 'lib-ui/button/button';
import schema from './registration-schema';
import toastFunctions from 'lib-ui/toast/Toast';
import useRegisterUser from 'core/api/client-portal-v2/hooks/auth/useRegisterUser';

function Register() {
    const { t } = useTranslation();
    const { isLoading: registering, mutate: registerUser } = useRegisterUser();
    const { signinRedirect } = useAuth();
    const goTo = useNavigate();
    const turnstile = useTurnstile();

    const [cloudFlareToken, setCloudFlareToken] = useState<string | undefined>(
        undefined
    );

    const methods = useForm<FormData>({
        defaultValues: {
            addressLine1: '',
            addressLine2: '',
            addressLine3: '',
            agreementNumber: undefined,
            cloudFlareToken: '',
            company: '',
            emailAddress: '',
            firstName: '',
            jobTitle: '',
            lastName: '',
            password: '',
            passwordConfirm: '',
            postcode: '',
            telephone: '',
            title: ''
        },
        mode: 'all',
        resolver: yupResolver(schema)
    });

    const submit = useCallback<SubmitHandler<FormData>>(
        (formData) => {
            const token = cloudFlareToken;
            const payload = {
                ...formData,
                cloudFlareToken: token
            };

            registerUser(
                { data: payload },
                {
                    onError(err) {
                        const { errors } = err.response.data;
                        const key = Object.keys(err.response.data.errors)[0];

                        toastFunctions.error(
                            errors[key][0] || t('toast.error')
                        );
                    },
                    onSuccess(res) {
                        toastFunctions.success(t('toast.success'));
                        setCloudFlareToken('reset');
                        goTo(`/confirm-registration`);
                    }
                }
            );
        },
        [cloudFlareToken, goTo, registerUser, t]
    );
    return (
        <div>
            <section className="w-full p-[7rem]" data-testid="layout-section">
                <h1 className="text-general-grey-grey-90 text-3xl pb-5.5 text-center">
                    <span className="font-semibold text-brand-escode-neonblue-neonblue-100">
                        View&nbsp;
                    </span>
                    {t('auth.register.title')}
                </h1>
                <FormProvider {...methods}>
                    <form onSubmit={methods.handleSubmit(submit)}>
                        <div className="flex flex-col gap-4">
                            <div className="grid grid-cols-3 gap-4">
                                <SelectField
                                    disabled={registering}
                                    name="title"
                                    label="Title"
                                    placeholder="Title"
                                    options={titles}
                                    required
                                />
                                <InputField
                                    disabled={registering}
                                    name="firstName"
                                    label="First Name"
                                    placeholder="First Name"
                                    required
                                />
                                <InputField
                                    disabled={registering}
                                    name="lastName"
                                    label="Last Name"
                                    placeholder="Last Name"
                                    required
                                />
                            </div>

                            <div className="grid grid-cols-3 gap-4">
                                <InputField
                                    disabled={registering}
                                    name="jobTitle"
                                    label="Job Title"
                                    placeholder="Job Title"
                                    required
                                />
                                <InputField
                                    disabled={registering}
                                    name="company"
                                    label="Company Name"
                                    placeholder="Company Name"
                                    required
                                />
                                <InputField
                                    disabled={registering}
                                    name="addressLine1"
                                    label="Address Line 1"
                                    placeholder="Address Line 1"
                                    required
                                />
                            </div>

                            <div className="grid grid-cols-3 gap-4">
                                <InputField
                                    disabled={registering}
                                    name="addressLine2"
                                    label="Address Line 2"
                                    placeholder="Address Line 2"
                                />
                                <InputField
                                    disabled={registering}
                                    name="addressLine3"
                                    label="Address Line 3"
                                    placeholder="Address Line 3"
                                />
                                <InputField
                                    disabled={registering}
                                    name="postcode"
                                    label="Post Code"
                                    placeholder="Post Code"
                                    required
                                />
                            </div>

                            <div className="grid grid-cols-2 gap-4">
                                <InputField
                                    disabled={registering}
                                    name="telephone"
                                    label="Telephone"
                                    placeholder="345872 8494"
                                    required
                                />
                                <InputField
                                    disabled={registering}
                                    name="emailAddress"
                                    label="Email Address"
                                    placeholder="name@email.com"
                                    required
                                />
                            </div>

                            <div className="grid grid-cols-1 gap-4">
                                <InputField
                                    disabled={registering}
                                    name="agreementNumber"
                                    label="Agreement number"
                                    placeholder="2734831"
                                    required
                                />
                            </div>

                            <div className="grid grid-cols-1 gap-4">
                                <div>
                                    <p> {t('auth.register.password')}</p>
                                    <p>
                                        {t(
                                            'auth.register.passwordRequirements'
                                        )}
                                    </p>
                                </div>

                                <div className="grid grid-cols-2 gap-4">
                                    <InputField
                                        disabled={registering}
                                        name="password"
                                        label="Password"
                                        placeholder="MyPassword1234!"
                                        type="password"
                                        required
                                    />
                                    <InputField
                                        disabled={registering}
                                        name="passwordConfirm"
                                        label="Confirm Password"
                                        placeholder="MyPassword1234!"
                                        type="password"
                                        required
                                    />
                                </div>
                                <CheckField
                                    id="acceptedTerms"
                                    label={
                                        <span>
                                            I accept the{' '}
                                            <a
                                                className="text-brand-escode-neonblue-neonblue-100 underline"
                                                href="/terms"
                                                target="_blank"
                                                rel="noreferrer"
                                            >
                                                {t('terms-and-conditions')}
                                            </a>
                                        </span>
                                    }
                                    name="acceptedTerms"
                                />
                                <div className="flex justify-between gap-4">
                                    <Turnstile
                                        appearance="execute"
                                        sitekey={
                                            process.env.REACT_APP_SITE_KEY!
                                        }
                                        onVerify={(token) => {
                                            setCloudFlareToken(token);
                                        }}
                                        refreshExpired="auto"
                                        retry="auto"
                                        onError={() => {
                                            turnstile.reset();
                                            turnstile.execute();
                                        }}
                                        onExpire={() => {
                                            turnstile.reset();
                                            turnstile.execute();
                                        }}
                                        onTimeout={() => {
                                            turnstile.reset();
                                            turnstile.execute();
                                        }}
                                    />
                                    <div className="flex flex-col justify-end items-end">
                                        <div>
                                            <Button
                                                disabled={registering}
                                                type="button"
                                                className="mr-2"
                                                onClick={() =>
                                                    turnstile.reset()
                                                }
                                            >
                                                <FontAwesomeIcon
                                                    icon={faArrowLeft}
                                                    className="text-sm text-brand-escode-neonblue-neonblue-100"
                                                />
                                                Reset turnstile
                                            </Button>
                                            <Button
                                                disabled={
                                                    !cloudFlareToken ||
                                                    registering
                                                }
                                                variant="primary"
                                                className="col-span-2"
                                            >
                                                {registering ? (
                                                    <FontAwesomeIcon
                                                        icon={faSpinner}
                                                        spin
                                                    />
                                                ) : (
                                                    t(
                                                        'auth.register.registerButton'
                                                    )
                                                )}
                                            </Button>
                                        </div>
                                        <div className="flex justify-end mt-2">
                                            <p>
                                                {t(
                                                    'auth.register.alreadyHaveAccount'
                                                )}
                                                &nbsp;
                                            </p>
                                            <button
                                                className="text-brand-escode-neonblue-neonblue-100"
                                                data-testid="link"
                                                disabled={registering}
                                                onClick={() => signinRedirect()}
                                                type="button"
                                            >
                                                {t('auth.register.logIn')}{' '}
                                                <FontAwesomeIcon
                                                    icon={faArrowRight}
                                                    className="text-sm text-brand-escode-neonblue-neonblue-100"
                                                />
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </form>
                </FormProvider>
            </section>
        </div>
    );
}

export default Register;
