/* eslint-disable sort-keys-fix/sort-keys-fix */
import * as yup from 'yup';

const requiredMessage = 'This field is required';
const maxLengthMessage = 'Must not exceed 500 characters';
const radioMessage = 'Please select an option';

const schema = yup.object({
    compressionMethod: yup.string().when('isCompressed', {
        is: 'yes',
        then: yup
            .string()
            .required('Compression method is required')
            .max(500, maxLengthMessage),
        otherwise: yup.string()
    }),
    containsPersonalData: yup
        .mixed<string | boolean>()
        .required(requiredMessage)
        .typeError(radioMessage),
    documentation: yup.string().max(500, maxLengthMessage),
    encryptMethod: yup.string().when('isEncrypted', {
        is: 'yes',
        then: yup
            .string()
            .required('Encryption method is required')
            .max(500, maxLengthMessage),
        otherwise: yup.string()
    }),
    exportControlsDetails: yup.string().when('isSubjectToAnyExportControls', {
        is: 'yes',
        then: yup.string().required(requiredMessage).max(500, maxLengthMessage),
        otherwise: yup.string()
    }),
    isCompressed: yup
        .mixed<string | boolean>()
        .required(requiredMessage)
        .typeError(radioMessage),
    isEncrypted: yup
        .mixed<string | boolean>()
        .required(requiredMessage)
        .typeError(radioMessage),
    isPasswordRequired: yup
        .mixed<string | boolean>()
        .required(requiredMessage)
        .typeError(radioMessage),
    isReplacementOfPreviousDeposit: yup.mixed<string | boolean>(),
    isSubjectToAnyExportControls: yup
        .mixed<string | boolean>()
        .required(requiredMessage)
        .typeError(radioMessage),
    password: yup
        .string()
        .when('isPasswordRequired', {
            is: 'yes',
            then: yup
                .string()
                .required(requiredMessage)
                // .min(8, 'Password must exceed 8 characters')
                .max(150, 'Password cannot exceed 150 characters'),
            otherwise: yup.string().notRequired()
        })
        .typeError('Please enter a valid password')
        .nullable(),
    personalDataDetails: yup.string().when('containsPersonalData', {
        is: 'yes',
        then: yup.string().required(requiredMessage),
        otherwise: yup.string()
    })
});

export default schema;
