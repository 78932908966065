const titles = [
    {
        label: 'Mr',
        value: 'mr'
    },
    {
        label: 'Mrs',
        value: 'mrs'
    },
    {
        label: 'Ms',
        value: 'ms'
    },
    {
        label: 'Sir',
        value: 'sir'
    }
];

type FormData = {
    acceptedTerms: boolean;
    addressLine1: string;
    addressLine2: string;
    addressLine3: string;
    agreementNumber: undefined;
    cloudFlareToken: string;
    company: string;
    emailAddress: string;
    firstName: string;
    jobTitle: string;
    lastName: string;
    password: string;
    passwordConfirm: string;
    postcode: string;
    telephone: string;
    title: string;
};

export type { FormData };
export { titles };
