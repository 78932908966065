import * as yup from 'yup';

const agreementNumberMessage = 'Please enter a valid agreement number';
const requiredMessage = 'This field is required';
const titleMessage = 'This field is required';

const schema = yup.object({
    acceptedTerms: yup
        .boolean()
        .oneOf([true], requiredMessage)
        .required(requiredMessage),
    addressLine1: yup.string().required(requiredMessage),
    agreementNumber: yup
        .number()
        .required(agreementNumberMessage)
        .typeError(agreementNumberMessage),
    cloudFlareToken: yup.string(),
    company: yup.string().required(requiredMessage),
    emailAddress: yup.string().required(requiredMessage),
    firstName: yup.string().required(requiredMessage),
    jobTitle: yup.string().required(requiredMessage),
    lastName: yup.string().required(requiredMessage),
    password: yup
        .string()
        .required(requiredMessage)
        .min(14, 'Password must be at least 14 characters long')
        .max(64, 'Password must not exceed 64 characters')
        .matches(/\d/, 'Password must contain at least one number')
        .matches(/[A-Z]/, 'Password must contain at least one uppercase letter')
        .matches(/[a-z]/, 'Password must contain at least one lowercase letter')
        .matches(
            /[!@#$%^&*(),.?":{}|<>]/,
            'Password must contain at least one special character'
        ),
    passwordConfirm: yup
        .string()
        .oneOf([yup.ref('password'), null], 'Passwords must match')
        .required(requiredMessage),
    postcode: yup.string().required(requiredMessage),
    telephone: yup.string().required(requiredMessage),
    title: yup.string().required(titleMessage).typeError(titleMessage)
});

export default schema;
