function splitIntoGroups<T>(array: T[], chunkSize = 20) {
    const size = chunkSize;
    const result = [];
    for (let i = 0; i < array.length; i += size) {
        const chunk = array.slice(i, i + size);
        result.push(chunk);
    }
    return result;
}

export default splitIntoGroups;
