import * as yup from 'yup';

const requiredMessage = 'This is required';
const countryMessage = 'This is required. Please select a country';

const schema = yup.object({
    addressLine1: yup
        .string()
        .required(requiredMessage)
        .max(250, 'Must not exceed 250 characters'),
    addressLine2: yup.string().max(250, 'Must not exceed 250 characters'),
    addressLine3: yup.string().max(250, 'Must not exceed 250 characters'),
    city: yup
        .string()
        .required(requiredMessage)
        .max(32, 'Must not exceed 32 characters'),
    companyName: yup
        .string()
        .required(requiredMessage)
        .max(500, 'Must not exceed 500 characters'),
    contactName: yup
        .string()
        .required(requiredMessage)
        .max(500, 'Must not exceed 500 characters'),
    country: yup.string().required(countryMessage).typeError(countryMessage),
    county: yup.string().max(32, 'Must not exceed 32 characters'),
    emailAddress: yup
        .string()
        .required(requiredMessage)
        .max(100, 'Must not exceed 100 characters'),
    jobTitle: yup
        .string()
        .required(requiredMessage)
        .max(256, 'Must not exceed 256 characters'),
    postCode: yup
        .string()
        .required(requiredMessage)
        .max(50, 'Must not exceed 50 characters'),
    telephoneNumber: yup
        .string()
        .required(requiredMessage)
        .max(50, 'Must not exceed 50 characters')
});

export default schema;
