import {
    type ForwardRefRenderFunction,
    ReactNode,
    forwardRef,
    memo
} from 'react';

import type React from 'react';

import cn from 'core/utils/cn';

interface CheckboxProps
    extends Omit<React.InputHTMLAttributes<HTMLInputElement>, 'type'> {
    error?: boolean;
    errorTextStyles?: boolean;
    label?: string | ReactNode;
    wrapperTailwindStyle?: string;
}

const CheckboxComponent: ForwardRefRenderFunction<
    HTMLInputElement,
    CheckboxProps
> = (
    {
        className,
        error,
        errorTextStyles = false,
        label,
        wrapperTailwindStyle,
        ...restProps
    },
    ref
) => {
    return (
        <div className={cn('flex items-center relative', wrapperTailwindStyle)}>
            <input type="checkbox" ref={ref} {...restProps} />
            {label && (
                <label
                    className={cn(
                        'ml-2 text-sm text-general-grey-grey-100',
                        restProps.disabled && 'text-general-grey-grey-60',
                        errorTextStyles && error && 'text-general-red-red-100'
                    )}
                    htmlFor={restProps.id}
                >
                    {label}
                </label>
            )}
        </div>
    );
};

const Checkbox = memo(forwardRef(CheckboxComponent));
Checkbox.displayName = 'Checkbox';

export default Checkbox;
export type { CheckboxProps };
